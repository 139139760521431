import { render, staticRenderFns } from "./EmployeeDashboard.vue?vue&type=template&id=27dd8fbb&scoped=true&"
import script from "./EmployeeDashboard.vue?vue&type=script&lang=js&"
export * from "./EmployeeDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27dd8fbb",
  null
  
)

export default component.exports