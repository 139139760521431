<template>
  <div>
    <WelcomeMessage :employee-data="employee"></WelcomeMessage>
    <MyAssignedBonusesTable></MyAssignedBonusesTable>
  </div>
</template>

<script>
import MyAssignedBonusesTable from '../components/MyAssignedBonusesTable.vue';
import WelcomeMessage from '../components/WelcomeMessage.vue';

export default {
  name: 'EmployeeDashboard',
  components: { WelcomeMessage, MyAssignedBonusesTable },
  computed: {
    employee() {
      return this.$store.state.employeeData || {};
    },
  },
};
</script>

<style lang="scss" scoped></style>
